<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">QUOカードPay</h3>
        <div class="title-left">
          <img src="../../../assets/images/quo-pay.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">QUOカードPay</h3>
            <p>QUOカードPayは、アプリや会員登録不要のスマートフォン決済型のデジタルギフトです。<br>
              現金併用ができるので、残高を最後まで使い切れます。<br>
              ・QUOカードPayの詳細は<a href="https://www.quocard.com/pay.html" class="text-green" target="_blank">こちら</a>をご覧ください。<br>
              ・QUOカードPayの利用方法は<a href="https://www.quocard.com/pay/use/" class="text-green" target="_blank">こちら</a>をご参照ください。<br>
              ・QUOカードPayの使える店舗は<a href="https://www.quocard.com/pay/store/" class="text-green" target="_blank">こちら</a>をご覧ください。</p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong class="unit-gpoint"><div>1pt →</div><span>1円相当</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">500pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container d-inline-block">
          <p>※ QUOカードPayは、バリューコードをお送りするタイプのデジタルギフトです。<br>
            ※ QUOカードPayはカードタイプのQUOカードとは異なるスマートフォン専用サービスです。<br>
            <br>
            <br>
            交換完了後はポイント通帳「ポイント交換履歴」から確認することができます。<br>
            <br>
            QUOカードPay ご注意事項<br>
            ※必ずお読みの後、ポイント交換申請を行ってください。<br>
            <br>
            「QUOカードPay」は、スマートフォンでのみご利用いただけます。その他の端末ではご利用いただけませんのでご注意ください。<br>
            ※その他の端末とは、PC、スマートフォン以外の携帯電話およびタブレットを指します。<br>
            有効期限は発行日より最大1年間になります。<br>
            期限切れによる失効につきましては、当社では一切の責任を負いかねますので、ご了承ください。<br>
            バリューコード（QUOカードPayのURL）に不備があった場合、必ず発行から7日以内にQUOカードPayまでお問い合わせください。8日以上経過するとお問い合わせ不可となりますのでご注意ください。<br>
            バリューコードのURLを再発行することは出来ません。<br>
            アプリにバリューコードを保存した場合は、バリューコードのURLは不要になります。<br>
            「QUOカードPay」は、カードタイプのQUOカードとは異なるサービスです。カードタイプのQUOカード加盟店ではご利用できない場合があるほか、カードタイプのQUOカードの残高を移行、チャージすることはできません。<br>
            ポイント交換申請後、手続き完了前に退会されますと交換ポイントは無効となりますので、予めご了承くださいませ。<br>
          </p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- BTN EXCHANGE POINT -->
        <button @click="handleBeforeExchangePoint"
                v-if="profile && token"
                class="btn-exchange-point">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを交換する
        </button>

        <!-- BTN redirect to login -->
        <button v-else @click="openPageNative('Login')"
                class="btn-exchange-point btn-redirect">
          すでに会員の方はこちらからログイン
        </button>

        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <!-- MODAL INPUT EXCHANGE POINT -->
    <Modal @close-modal="flagModalInput = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalInput">
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="validateBeforeSubmit()"
        slot="body"
        class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;QUOカードPay</h3>
        <p class="sub-title">※QUOカードPayへの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange">
          <span>交換</span>
          <InputField v-model="pointExchange"
                      field="交換ポイント"
                      type="text"
                      class="point-type"
                      :max-length="15"
                      @input="handleNumber($event)"
                      @focusout="handleNumberFocusOut()"
                      @focusin="handleNumberFocusIn($event)"
                      :rules="{
                        required: true,
                        number_exchange: true,
                        compare_point: {
                          first: pointExchange && parseInt(pointExchange.replace(/,/g, '')),
                          second: user_point.total_point_amount
                        },
                        range_point: true
                       }"
                      vid="point"/>
          <span class="pc">pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange point-line">
          <span>付与</span>
          <InputField v-model="pointExchange"
                      class="point-type"
                      :disabled="true"/>
          <span class="pc">円相当</span>
        </div>

        <!-- NOTE -->
        <p class="note">交換するポイントを入力して交換ポイントを確定するボタンをクリックしてください。</p>
      </ValidationObserver>

      <div class="btn-footer"
           slot="footer">
        <button @click.prevent="validateBeforeSubmit">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換ポイントを確定する
        </button>
      </div>
    </Modal>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalConfirm">
      <div slot="body"
           class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;QUOカードPay</h3>
        <p class="sub-title">※QUOカードPayへの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange confirm"
             :class="{ 'point-input' : !flagModalConfirm }">
          <span>交換</span>
          <strong class="point">{{ pointExchange }}</strong>
          <span>pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img v-if="flagModalConfirm" class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange confirm">
          <span :class="{ 'orange': !flagModalConfirm }">付与</span>
          <strong>{{ pointExchange }}</strong>
          <span :class="{ 'orange': !flagModalConfirm }">円相当</span>
        </div>

        <!-- NOTE MODAL CONFIRM -->
        <p class="note text-center">交換申請するボタンをクリックするとポイント交換が完了となります。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="handleExchangePoint">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請する
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-exchange-point modal-center"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp">&#12288;QUOカードPay</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ pointExchange }}<span class="color-orange sp" v-html="'円相当'"></span></strong>
            <time
              class="time-request"
              v-if="dataComplete.value_code"
            >
              交換承認日：
              <span>
                {{ new Date() | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange"
                v-html="'円相当'"></span>
        </div>

        <div class="voucher" v-if="dataComplete.value_code">
          <span class="font-weight-bold">バリューコード</span>
          <p class="mb-2">{{ dataComplete.value_code }}</p>
          <time class="font-weight-bold">有効期限：<span>{{ dataComplete.expire_date | formatDate('YYYY年MM月DD日') }}</span></time>
        </div>

        <p
          class="text-instruct"
          v-if="dataComplete.value_code"
        >
          「QUOカードPayへ移動」ボタンからQUOカードPayへ遷移し
          <br class="pc">QUOカードPayをお受け取りください
        </p>

        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
      </div>

      <div
        class="btn-footer"
        slot="footer"
        v-if="dataComplete.value_code"
      >
        <button class="btn-common"
                @click.prevent="openUrl(dataComplete.value_code_url)">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">QUOカードPayへ移動
        </button>
      </div>
    </Modal>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
      <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>ポイント交換前に必要事項をご入力ください。</p>

            <button
                    @click.prevent="handelRedirectUpdateInfo"
                    v-html="'会員情報入力'"
                    class="btn-into-modal"/>
          </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
import { ChevronLeftIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'
import SNS from '@/mixins/sns.mixin'
import store from '@/store'
import { mapActions } from 'vuex'
import Modal from '@/components/advertising-detail/Modal'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import InputField from '@/components/form/InputField'

export default {
  name: 'Index',

  mixins: [Common, SNS],

  components: {
    ModalVerifyAuthenMobile,
    InputField,
    Modal,
    ChevronLeftIcon
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})

      if (this.token) {
        store.dispatch('auth/userPoint')
      }
    }
  },

  methods: {
    ...mapActions('quoPay', ['quopayExchangePoint']),

    async handleExchangePoint () {
      this.onExchangePoint(this.quopayExchangePoint)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';
</style>
